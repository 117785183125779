// Function to init sticky Sidebar
import $ from 'jquery';

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/

const article = () => {
  $('.readmore').click(() => {
    $('html, body').animate(
      {
        scrollTop: $('#scroll_target').offset().top - 50,
      },
      1000
    );
  });

  $('.comment-button-text').click(() => {
    $('html, body').animate(
      {
        scrollTop: $('#respond').offset().top - 50,
      },
      1000
    );
  });
};
export default article;
