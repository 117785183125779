import $ from 'jquery';

export default () => {
  $('a[href=#login]').click((e) => {
    // prevent the user to open the learndash login popup
    e.preventDefault();
    $('.ld-login-modal').remove();
    // the Sanoma var is loaded completly external. So no import possible
    // eslint-disable-next-line no-undef
    Sanoma.account.UI.showScreen('login');
  });
};
