import $ from 'jquery';

export default () => {
  $('#voucher-form').on('submit', (e) => {
    e.preventDefault();
    const formdata = $(e.target).serialize();
    $.ajax({
      url: $(e.target).attr('action'),
      type: 'post',
      data: formdata,
    }).done((response) => {
      const data = JSON.parse(response);
      const elementClass = 'status ';
      $('#voucher-form .status').html(data.message);
      $('#voucher-form .status')
        .removeClass()
        .addClass(elementClass + data.status);
      if (data.status === 'success') {
        $('#voucher-form #voucher-code').val('');
      }
      if (data.update_html) {
        $(data.update_html.element).html(data.update_html.html);
        $('.ld-profile-edit-link').remove();
        $('.ld-profile-avatar').remove();
        $('.ld-search-prompt').remove();
      }
    });
  });
};
