// Import scripts and run them
import '../scss/style.scss';
import slick from './modules/slickslider';
import sticky from './modules/stickysidebar';
import mobile from './modules/mobile';
import article from './modules/article';
import ajax from './modules/ajax';
import scrollButton from './modules/scrollButton';
import header from './modules/header';
import saAccount from './modules/saAccount';
import learndash from './modules/learndash';
import jwplayer from './modules/jwplayer';
import vgVoucher from './modules/vgVoucher';
import login from './modules/login';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    mobile();
    slick();
    sticky();
    article();
    ajax();
    scrollButton();
    header();
    saAccount();
    learndash();
    jwplayer();
    vgVoucher();
    login();
  },
  false
);
