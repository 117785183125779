// Function to init sticky Sidebar
import $ from 'jquery';
import StickySidebar from 'sticky-sidebar';

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/

// eslint-disable-next-line import/no-default-export
export default function sticky() {
  if ($('.s-sidebar').length === 0) {
    return;
  }
  // eslint-disable-next-line no-new
  new StickySidebar('.s-sidebar', {
    containerSelector: '.s-sidebar',
    innerWrapperSelector: '.s-sidebar__inner',
  });
}
