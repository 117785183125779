import $ from 'jquery';

export default () => {
  window.addEventListener('load', () => {
    $('.jwplayer-custom-video').each((index, element) => {
      const id = $(element).data('id');
      const playlist = [
        {
          file: $(element).data('url'),
          description: $(element).data('description') || '',
          image: `https://content.jwplatform.com/thumbs/${id}-1920.jpg` || '',
          mediaid: $(element).data('mediaid') || '',
          title: $(element).data('title') || '',
        },
      ];

      const setup = {
        playlist,
        autostart: !!$(element).data('autostart') || false,
        stretching: $(element).data('stretching') || 'uniform',
        mute: !!$(element).data('mute') || false,
        repeat: !!$(element).data('repeat') || false,
        height: $(element).data('height') || '100%',
        width: $(element).data('width') || '100%',
      };
      window.jwplayer(element).setup(setup);
    });
  });
};
