// Function to init sticky Sidebar
import $ from 'jquery';

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/

export default function ajax() {
  let fetchingArticles = false;
  // if something breaks from ajax this is spot number 1 to look
  // admin was before not defined in the file but eslint forced it.
  // added it as a parameter to the .click()
  $('.js-load-more-articles').click((admin) => {
    const offset = $(this).attr('data-offset');
    const self = $(this);
    const category = $(this).attr('data-category-id');
    const tag = $(this).attr('data-tag-id');

    if (fetchingArticles) {
      return;
    }

    fetchingArticles = true;

    const data = {
      action: 'load_more_articles',
      offset,
    };

    if (category !== undefined) {
      data.category = category;
    }

    if (tag !== undefined) {
      data.tag = tag;
    }

    $.get(admin.ajaxUrl, data, (response) => {
      $('.homepage-blocks').append(response.data.html);
      self.attr('data-offset', response.data.offset);
      fetchingArticles = false;

      if (response.data.hideButton === true) {
        self.remove();
      }
    });
  });
}
