// Function to init sticky Sidebar
import $ from 'jquery';

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/

export default () => {
  $('.mobile-button').click(() => {
    $('body').toggleClass('mobile-active');
    $('.nav-mobile-holder').toggleClass('active');
    return false;
  });

  $('.close-mobile').click(() => {
    $('body').removeClass('mobile-active');
    $('.nav-mobile-holder').removeClass('active');
  });

  $('li.menu-item-has-children > a').click(() => {
    $(this).next().toggleClass('active');
    return false;
  });

  $('.fa-chevron-right').click(() => {
    $('.nav-drop').addClass('active');
    return false;
  });

  $('.nav-drop .back-btn').click(() => {
    $(this).parent().removeClass('active');
  });

  $('footer .s-column__wrapper div h2').click(function callBack() {
    $(this).next().find('.menu').slideToggle();
    $(this).toggleClass('active');
  });
};
